import React from "react";
import { Heading, Box, Card, Flex } from "theme-ui";

import {
  Percent,
  Difference,
  Decimalish,
  Decimal,
  Trove,
  LiquityStoreState,
  LUSD_LIQUIDATION_RESERVE
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN } from "../../strings";

import { Icon } from "../Icon";
import { StaticRow } from "./Editor";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { LiquidationPrice } from "./LiquidationPrice";
import { InfoIcon } from "../InfoIcon";

import { calcLiquidationPrice, calcLiquidationPriceInRecoveryMode } from "../../utils/troveUtils";

type TroveEditorProps = {
  original: Trove;
  edited: Trove;
  fee: Decimal;
  borrowingRate: Decimal;
  changePending: boolean;
  dispatch: (
    action: { type: "setCollateral" | "setDebt"; newValue: Decimalish } | { type: "revert" }
  ) => void;
};

const select = ({ price }: LiquityStoreState) => ({ price });

export const TroveEditor: React.FC<TroveEditorProps> = ({
  children,
  original,
  edited,
  fee,
  borrowingRate,
  changePending
}) => {
  const { price } = useLiquitySelector(select);

  const feePct = new Percent(borrowingRate);

  const originalCollateralRatio = !original.isEmpty ? original.collateralRatio(price) : undefined;
  const collateralRatio = !edited.isEmpty ? edited.collateralRatio(price) : undefined;
  const collateralRatioChange = Difference.between(collateralRatio, originalCollateralRatio);

  const originalLiquidationPrice = !original.isEmpty ? calcLiquidationPrice(original) : undefined;
  const originalLiquidationPriceRecovery = !original.isEmpty ? calcLiquidationPriceInRecoveryMode(original) : undefined;
  const liquidationPrice = !edited.isEmpty ? calcLiquidationPrice(edited) : undefined;
  const liquidationPriceChange = Difference.between(liquidationPrice, originalLiquidationPrice);

  const liquidationPriceRecovery = !edited.isEmpty ? calcLiquidationPrice(edited) : undefined;
  const liquidationPriceRecoveryChange = Difference.between(liquidationPriceRecovery, originalLiquidationPriceRecovery);

  return (
    <Card>
      <Heading>
        <div>
          <Icon name="piggy-bank" style={{ marginRight: '12px' }} />
          Trove
        </div>
      </Heading>

      <Box sx={{ p: [2, 3] }}>
        <StaticRow
          label="Collateral"
          inputId="trove-collateral"
          amount={edited.collateral.prettify(4)}
          unit="ETH"
        />

        <StaticRow label="Debt" inputId="trove-debt" amount={edited.debt.prettify()} unit={COIN} />

        {original.isEmpty && (
          <Flex>
            <Box sx={{ mt: [2, 0], ml: 3, width: '24px', fontSize: "24px", textAlign: 'center' }}>
              <Icon name="gas-pump" />
            </Box>
            <StaticRow
              label="Liquidation Reserve"
              inputId="trove-liquidation-reserve"
              amount={`${LUSD_LIQUIDATION_RESERVE}`}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "200px" }}>
                      An amount set aside to cover the liquidator’s gas costs if your Trove needs to be
                      liquidated. The amount increases your debt and is refunded if you close your
                      Trove by fully paying off its net debt.
                    </Card>
                  }
                />
              }
            />
          </Flex>
        )}

        
        <Flex>
          <Box sx={{ mt: [2, 0], ml: 3, width: '24px', fontSize: "24px", textAlign: 'center' }}>
            <Icon name="hand-holding-usd" />
          </Box>
          <StaticRow
            label="Borrowing Fee"
            inputId="trove-borrowing-fee"
            amount={fee.toString(2)}
            pendingAmount={feePct.toString(2)}
            unit={COIN}
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ width: "240px" }}>
                    This amount is deducted from the borrowed amount as a one-time fee. There are no
                    recurring fees for borrowing, which is thus interest-free.
                  </Card>
                }
              />
            }
          />
        </Flex>

        <LiquidationPrice value={liquidationPrice} change={liquidationPriceChange} />
        <LiquidationPrice value={liquidationPriceRecovery} change={liquidationPriceRecoveryChange} recovery={true} />
        <CollateralRatio value={collateralRatio} change={collateralRatioChange} />

        {children}
      </Box>

      {changePending && <LoadingOverlay />}
    </Card>
  );
};
