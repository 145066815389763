import React from "react";
import { Flex, Container } from "theme-ui";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Wallet } from "@ethersproject/wallet";

import { Decimal, Difference, Trove } from "@liquity/lib-base";
import { LiquityStoreProvider } from "@liquity/lib-react";

import { useLiquity } from "./hooks/LiquityContext";
import { TransactionMonitor } from "./components/Transaction";
import { UserAccount } from "./components/UserAccount";
import { SystemStatsPopup } from "./components/SystemStatsPopup";
import { Header } from "./components/Header";

import { PageSwitcher } from "./pages/PageSwitcher";
import { Farm } from "./pages/Farm";
import { Staking } from "./pages/Staking";
import { TrovePage } from "./pages/TrovePage";
import { RiskyTrovesPage } from "./pages/RiskyTrovesPage";


import { TroveViewProvider } from "./components/Trove/context/TroveViewProvider";
import { StabilityViewProvider } from "./components/Stability/context/StabilityViewProvider";
import { StakingViewProvider } from "./components/Staking/context/StakingViewProvider";
import { FarmViewProvider } from "./components/Farm/context/FarmViewProvider";
import { StabilityPool } from "./pages/StabilityPool";

type LiquityFrontendProps = {
  loader?: React.ReactNode;
};
export const LiquityFrontend: React.FC<LiquityFrontendProps> = ({ loader }) => {
  const { account, provider, liquity } = useLiquity();

  // For console tinkering ;-)
  Object.assign(window, {
    account,
    provider,
    liquity,
    Trove,
    Decimal,
    Difference,
    Wallet,
  });

  return (
    <LiquityStoreProvider {...{ loader }} store={liquity.store}>
      <Router>
        <TroveViewProvider>
          <StabilityViewProvider>
            <StakingViewProvider>
              <FarmViewProvider>
                <Flex
                  sx={{
                    flexDirection: "column",
                    minHeight: "100%",
                  }}
                >
                  <Header>
                      <UserAccount />
                      <SystemStatsPopup />
                    </Header>
                  <Switch>
                    <Route path="/" exact>
                    <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                      <PageSwitcher />
                      </Container>
                    </Route>
                    <Route path="/farm">
                   
                      <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Farm />
                      </Container>
                    </Route>
                    <Route path="/staking">
                   
                      <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Staking />
                      </Container>
                    </Route>
                    <Route path="/trove">
                 
                      <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TrovePage />
                      </Container>
                    </Route>
                    <Route path="/stability-pool">
                   
                      <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <StabilityPool />
                      </Container>
                    </Route>
                    <Route path="/risky-troves">
                   
                      <Container
                        variant="main"
                        sx={{
                          display: "flex",
                          flexGrow: 1,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <RiskyTrovesPage />
                      </Container>
                    </Route>
                    {/* <Route path="/risky-troves">
                        <RiskyTrovesPage />
                      </Route> */}
                    {/* <Route path="/redemption">
                        <RedemptionPage />
                      </Route> */}
                  </Switch>
                </Flex>
              </FarmViewProvider>
            </StakingViewProvider>
          </StabilityViewProvider>
        </TroveViewProvider>
      </Router>
      <TransactionMonitor />
    </LiquityStoreProvider>
  );
};
