import { Flex, Box } from "theme-ui";
import { Link } from "./Link";

// import { useColorMode } from 'theme-ui'

export const Nav: React.FC = () => {
  // const [colorMode, setColorMode] = useColorMode()

  return (
    <Box as="nav" sx={{ display: ["none", "none", "flex"], alignItems: "center", flex: 1 }}>
      <Flex>
        <Link to="/">Dashboard</Link>
        {/* <Link sx={{ pl: 0 }} to="/trove">Trove</Link>
        <Link sx={{ pl: 0 }} to="/stability-pool">Stability Pool</Link>
        <Link sx={{ pl: 0 }} to="/staking">Stake</Link> */}
        <Link to="/farm">Farm</Link>
        <Link to="/risky-troves">Risky Troves </Link>

      </Flex>
      <Flex sx={{ justifyContent: "flex-end", mr: 3, flex: 1 }}>
        {/* <Link sx={{ fontSize: 1 }} to="/risky-troves">
          Risky Troves
        </Link> */}
        {/* <Link sx={{ fontSize: 1 }} to="/redemption">
          Redemption
        </Link> */}
        {/* <button
        onClick={(e) => {
          setColorMode(colorMode === 'default' ? 'dark' : 'default')
        }}>
        Toggle {colorMode === 'default' ? 'Dark' : 'Light'}
      </button> */}

      </Flex>
    </Box>
  );
};
