import { Container } from "theme-ui";
import { Stability } from "../components/Stability/Stability";
import { SystemStats } from "../components/SystemStats";
import { MainNoticeCard } from '../components/MainNoticeCard';


export const StabilityPool: React.FC = () => {
  return (
    <Container variant="columns">
      <Container variant="left">
        <MainNoticeCard />
          <Stability />
      </Container>
      <Container variant="right">
          <SystemStats />
      </Container>
    </Container>
  );
};
