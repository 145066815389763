import { Container } from "theme-ui";
import { Staking as Stake } from "../components/Staking/Staking";
import { SystemStats } from "../components/SystemStats";
import { MainNoticeCard } from '../components/MainNoticeCard';


export const Staking: React.FC = () => (
    <Container variant="columns">
      <Container variant="left">
        <MainNoticeCard />
        <Stake />
      </Container>
  
      <Container variant="right">
        <SystemStats />
      </Container>
    </Container>
  );