import React, { useState, useEffect } from "react";
import { Card, Flex, Paragraph, Link, Image, Spinner, Box } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import CoinGecko from 'coingecko-api';

import { InfoIcon } from './InfoIcon';
import { Icon } from './Icon';

const selectPrice = ({ price }: LiquityStoreState) => price;

export const PriceManager: React.FC = () => {
  const price = useLiquitySelector(selectPrice);

  const [loading, setLoading] = useState(true);

  const defaultPrices: { [key: string]: { [key: string]: string } } = {}
  const [coinGeckoPrices, setCoinGeckoPrices] = useState(defaultPrices);

  const CoinGeckoClient = new CoinGecko();

  useEffect(() => {
    let timer: any;
    async function fetchPrices() {
      console.log('Fetching new prices...')
      try {
        let res = await CoinGeckoClient.simple.price({
          ids: ['ethereum', 'liquity', 'liquity-usd'],
          vs_currencies: ['usd'],
        });
        if (res.success) {
          setCoinGeckoPrices(res.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(`Error fetching prices!`, e)
      }
      if (timer) clearTimeout(timer);
      timer = setTimeout(fetchPrices, 15000);
    }
    fetchPrices();
    return () => timer && clearTimeout(timer);
    // For some reason, adding CoinGeckoClient.simple as a dependency creates serious issues, so ignore that
    // eslint-disable-next-line
  }, [])

  return (
      <Flex sx={{ alignItems: "center", justifyContent: "center", textAlign: 'center' }}>
      {loading ? (
        <>
        <Spinner sx={{ m: 2, color: "text" }} size="32px" />
        <span>Loading prices...</span>
        </>
      ) : (
        <>
          <Box style={{flexGrow:1,flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
            <Image src="/eth-icon.svg" sx={{height: '32px'}} />
            <div>
              <strong>ETH</strong>
            </div>
            <div>
              ${price.toString(2)}
            </div>
            <div>
            <small>
              Oracle Price
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ width: ["220px", "518px"] }}>
                    <Paragraph>
                      The Liquity Protocol uses the <Link href="https://data.chain.link/eth-usd" target="_blank">Chainlink ETH/USD price feed <Icon name="external-link-alt" /></Link> as an Oracle for the price of ETH. This price feed determines System and Trove Collateral Ratios, and when liquidations can happen.
                    </Paragraph>
                  </Card>
                }
              ></InfoIcon>
            </small>
            </div>
            <div>
              <Link href="https://www.coingecko.com/en/coins/ethereum" target="_blank" title="CoinGecko Information"><Icon name="info-circle" /></Link>
              &nbsp;&nbsp;
              <Link href="https://data.chain.link/eth-usd" target="_blank" title="Chainlink ETH/USD Price Feed"><Icon name="satellite-dish" /></Link>
            </div>
          </Box>
          <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
            <Image src="/eth-icon.svg" sx={{height: '32px'}} />
            <div>
              <strong>ETH</strong>
            </div>
            <div>
              ${coinGeckoPrices['ethereum'].usd}
            </div>
            <div>
              <small>Market Price</small>
            </div>
            <div>
              <Link href="https://www.coingecko.com/en/coins/ethereum" target="_blank" title="CoinGecko Information"><Icon name="info-circle" /></Link>
            </div>
          </Box>
          <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
            <Image src="/icons/128-lqty-icon.svg" sx={{height: '32px'}} />
            <div>
              <strong>LQTY</strong>
            </div>
            <div>
              ${coinGeckoPrices['liquity'].usd}
            </div>
            <div>
              <small>Market Price</small>
              </div>
            <div>
              <Link href="https://www.coingecko.com/en/coins/liquity" target="_blank" title="CoinGecko Information"><Icon name="info-circle" /></Link>
              &nbsp;&nbsp;
              <Link href="https://etherscan.io/token/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d" target="_blank" title="Contract Address"><Icon name="file-contract" /></Link>
              &nbsp;&nbsp;
              <Link href="https://app.uniswap.org/#/swap?outputCurrency=0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d" target="_blank" title="Swap on Uniswap"><Icon name="exchange-alt" /></Link>
            </div>
          </Box>
          <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
            <Image src="/icons/128-lusd-icon.svg" sx={{height: '32px'}} />
            <div>
            <strong>LUSD</strong>
            </div>
            <div>
              ${parseFloat(coinGeckoPrices['liquity-usd'].usd).toFixed(2)}
            </div>
            <div>
              <small>Market Price</small>
            </div>
            <div>
              <Link href="https://www.coingecko.com/en/coins/liquity-usd" target="_blank" title="CoinGecko Information"><Icon name="info-circle" /></Link>
              &nbsp;&nbsp;
              <Link href="https://etherscan.io/token/0x5f98805a4e8be255a32880fdec7f6728c6568ba0" target="_blank" title="Contract Address"><Icon name="file-contract" /></Link>
              &nbsp;&nbsp;
              <Link href="https://app.uniswap.org/#/swap?inputCurrency=0x5f98805a4e8be255a32880fdec7f6728c6568ba0" target="_blank" title="Swap on Uniswap"><Icon name="exchange-alt" /></Link>
            </div>
          </Box>
        </>
      )}
      </Flex>
  );
};